// Brands.js

import React, { useState, useEffect } from "react";
import BrandsMobile from "./BrandsMobile";
import BrandsDesktop from "./BrandsDesktop"; // Your existing desktop component
// import "./Brands.css";

const Brands = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Your existing brandsData object here
  const brandsData = {
    A: [
      "Apple",
      "Amazon",
      "Amazon Prime Video",
      "Alice + Olivia",
      "Adidas",
      "Adidas Originals",
      "Armani Exchange",
      "Ariel",
      "Asics",
      "Anastasia Beverly Hills",
      "Aldo",
      "Asus",
      "Australian Tourism",
      "Anomaly",
      "Aveda",
    ],
    B: [
      "Bobbi Brown",
      "Bottega Veneta",
      "Braun Beauty",
      "Bayern Munich FC",
      "Bacardi",
      "Benefit Cosmetics",
      "BeReal",
      "Becca Cosmetics",
      "B612",
      "Bvlgari",
      "Biolage",
      "Bumble",
      "Bath & Body Works",
    ],
    C: [
      "Coach",
      "Cannes",
      "Cadbury",
      "Canali",
      "Coke",
      "Coke Studio",
      "Charlotte Tilbury",
      "Changi Airport",
      "Crocs",
      "Collective",
      "Cosmopolitan",
      "Colgate",
      "Crunchyroll",
      "Celio",
      "Clinique",
      "Charles & Kieth",
      "Chambor",
      "Cetaphil",
      "Caudalie",
      "CA Osasuna",
      "Charmacy Milano",
    ],
    D: [
      "Delsey Paris",
      "Diesel",
      "Disney",
      "Disney +Hotstar",
      "Dune",
      "Daniel Wellington",
      "Dove",
      "Dominoes",
      "Duolingo",
      "Dyson",
      "Dolls Kill",
      "Doritos",
      "DC",
      "Dermologica",
    ],
    E: [
      "Etihad Airways",
      "E.l.f. Cosmetics",
      "Elle Carnival",
      "Estée Lauder",
      "ESPN",
      "Elizabeth Arden",
      "Eight Other Reasons",
      "Etude House",
    ],
    F: [
      "Fanta",
      "Formula 1",
      "FC Barcelona",
      "Fashion Nova",
      "Fossil",
      "Flipkart",
      "Fancode",
      "Falguni Shane Peacock",
      "French Connection",
      "Facebook",
      "Forbes",
    ],
    G: ["Garnier", "Google", "Gillete", "Garena", "Givenchy Beauty"],
    H: ["Huda Beauty", "Hyundai", "Happn", "HP", "Hershey’s", "H&M"],
    I: [
      "IMDb",
      "iQOO",
      "Indigo Airlines",
      "IIFA",
      "Ikea",
      "Inde Wild",
      "Innisfree",
      "Instagram",
    ],
    J: ["Jockey", "Jim Beam", "Jack & Jones", "Joe Malone", "Jacob & Co"],
    K: ["Kit Kat", "Kiehl’s", "Kotex", "Knorr", "KFC"],
    L: [
      "London Fashion Week",
      "L’Oréal Paris",
      "LaLiga",
      "Lancôme",
      "Lifebuoy",
      "L.G",
      "LionsGate",
      "Logitech",
      "Laneige",
      "Love Beauty & Planet",
      "LV",
    ],
    M: [
      "Milan Fashion Week",
      "Melbourne Fashion Week",
      "MAC",
      "Maybelline",
      "META",
      "Micheal Kors",
      "Marvel",
      "Marina Bay",
      "MTV",
      "Mx Player",
      "Matrix",
      "Maven Beauty",
      "Miniso",
      "Minute Maid",
      "McDonalds",
      "Masterchef Australia",
      "Makeup Forever",
      "Morphe",
      "Missguided",
      "Mario Badesco",
    ],
    N: [
      "New York Fashion Week",
      "Netflix",
      "Nestlé",
      "NBA",
      "NYX",
      "Nova men",
      "Naked Wolfe",
      "Novology",
      "Nickelodeon",
      "Nissan",
      "Neutriderm",
      "Na-kd",
      "Nivea",
      "Neutrogena",
    ],
    O: [
      "Onitsuka Tiger",
      "Oppo",
      "One Plus",
      "Olaplex",
      "OctaFX",
      "ONMO",
      "Old Spice",
      "Ordinary",
      "Olay",
    ],
    P: [
      "Paris Fashion Week",
      "Prada",
      "Pinterest",
      "Pond’s",
      "Phillips",
      "Puma",
      "Pizza Hut",
      "Pepsi",
      "Pringles",
      "Palmolive",
      "Parimatch",
      "Premier League",
      "Pebble Watch",
      "Pixi Beauty",
    ],
    Q: ["Qatar Airways"],
    R: [
      "Rare Beauty",
      "Reebok",
      "Revolve",
      "RealMe",
      "RedMI",
      "Ralph Lauren",
      "Rado",
      "Rimmel London",
      "Redbull",
      "Retrofête",
    ],
    S: [
      "Swiss Tourism",
      "Swarovski",
      "Snickers",
      "Sony Pictures",
      "Sony Music",
      "Steve Madden",
      "Superdry",
      "Sketchers",
      "Star Sports",
      "Simple Skincare",
      "Schwarzkoph",
      "Stayfree",
      "Skillshare",
      "Samsung",
      "Smashbox",
      "Snapchat",
      "Sephora",
      "Skin Story",
      "Schweppes",
      "Sol de Janeiro",
    ],
    T: [
      "Too Faced",
      "TUMI",
      "Thumbs Up",
      "Tomorrowland",
      "Techno Mobile",
      "TRESemmé",
      "Tide",
      "Toyota",
      "Triller",
      "TikTok",
      "Threads",
      "Twitter",
      "Tods",
      "Tranoï",
    ],
    U: ["Uniqlo", "Urbanic", "Uber", "Universal Music Group"],
    V: [
      "Vidcon",
      "Vivo",
      "Volvo",
      "Victoria’s Secret",
      "VisaCard",
      "Vita",
      "Vicks",
      "Venus",
      "Veet",
      "Vector & Rolf",
      "Vaseline",
      "Vouge",
      "Versace",
    ],
    W: [
      "Wink",
      "White Crow",
      "Whisper",
      "Wella",
      "Warner Music",
      "Warner Bros",
      "Wimbledon",
      "W Hotels",
    ],
    X: ["Xioami"],
    Y: ["YouTube", "Yamaha", "Yas Island", "YSL"],
    // Z: ["ZouTube", "Zamaha", "Zas Island", "ZSL"],
  };
  

  return isMobile ? (
    <BrandsMobile brandsData={brandsData} />
  ) : (
    <BrandsDesktop brandsData={brandsData} />
  );
};

export default Brands;
